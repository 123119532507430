import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="calc-home-visit-booking-amount-controller"
export default class extends Controller {
  static values = { price: Number }
  static targets = ['number', 'amount']

  connect() {
    this.calculateAmount()
  }

  calculateAmount() {
    const number = this.numberTarget.value
    const totalPrice = this.priceValue * number;
    this.amountTarget.innerHTML = `￥${totalPrice.toLocaleString('ja-JP')}`;
  }

  onNumberChange() {
    this.calculateAmount()
  }
}
