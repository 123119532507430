import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['price', 'platformFee', 'guideShare']

  connect() {
    this.calculate() // 初期化時に計算を実行
  }

  calculate() {
    const platformFeePercentage = parseFloat(
      this.data.get('platformFeePercentage')
    )

    const price = parseFloat(this.priceTarget.value) || 0

    // 決済手数料とプラットフォーム手数料の計算
    const platformFee = price * (platformFeePercentage / 100)

    // ガイドの取り分 = 入力金額 - (決済手数料 + プラットフォーム手数料)
    const guideShare = price - platformFee

    // 各値を表示
    this.platformFeeTarget.textContent = platformFee.toFixed(0)
    this.guideShareTarget.textContent = guideShare.toFixed(0)
  }
}
