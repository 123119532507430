import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="rating"
export default class extends Controller {
  static targets = ['star', 'input']

  connect() {
    // デフォルトの rating を設定（値が空なら4にする）
    if (!this.inputTarget.value) {
      this.inputTarget.value = 3
    }
    this.highlightStars(this.inputTarget.value)
  }

  setRating(event) {
    const rating = event.currentTarget.dataset.value
    this.inputTarget.value = rating
    this.highlightStars(rating)
  }

  highlightStars(rating) {
    this.starTargets.forEach(star => {
      star.classList.toggle('text-yellow-400', star.dataset.value <= rating)
      star.classList.toggle('text-gray-300', star.dataset.value > rating)
    })
  }
}
