import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="booking-form-anchor-controller"
export default class extends Controller {
  static values = { anchorId: String }

  connect() {
    const anchor = this.anchorIdValue;

    if (anchor) {
      setTimeout(() => {
        const targetElement = document.getElementById(anchor);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // 100ms 遅延を入れることで、確実に動作させる
    }
  }
}
