import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/airbnb.css'

// Connects to data-controller="flatpickr"
export default class extends Controller {
  connect() {
    // すでに flatpickr が適用されている場合はスキップ
    if (this.element.dataset.flatpickrInitialized) {
      return
    }
    this.element.dataset.flatpickrInitialized = true // 初回実行時にフラグを設定

    const kind = this.element.dataset.kind

    let options = {
      allowInput: true // `required` を有効にするため
    }

    switch (kind) {
      case 'home-visit-booking':
        options = { ...options, dateFormat: 'm/d/Y', minDate: 'today' }
        break
      case 'online-planning':
        options = {
          ...options,
          dateFormat: 'm/d/Y H:i',
          enableTime: true,
          time_24hr: false,
          minDate: new Date()
        }
        break
      case 'online-planning-date':
        options = { ...options, dateFormat: 'm/d/Y', minDate: 'today' }
        break
      case 'all-date':
        options = {
          ...options,
          dateFormat: 'Y-m-d',
          altInput: true,
          altFormat: 'm/d/Y'
        }
        break
      case 'date-from-today':
        options = {
          ...options,
          dateFormat: 'Y-m-d',
          altInput: true,
          altFormat: 'm/d/Y',
          minDate: 'today'
        }
        break
      case 'only-time':
        options = {
          enableTime: true,
          noCalendar: true
        }
        break
      case 'birthday':
        options = {
          ...options,
          dateFormat: 'Y-m-d',
          altInput: true,
          altFormat: 'm/d/Y',
          maxDate: 'today',
          minDate: '1900-01-01',
          defaultDate: null,
          onOpen: function (selectedDates, dateStr, instance) {
            instance.currentYear = 1990 // カレンダーを開いたときに 1990 年を表示
            instance.redraw()
          }
        }
        break
      default:
        options = {
          ...options,
          enableTime: true,
          dateFormat: 'Y-m-d H:i',
          altInput: true,
          altFormat: 'm/d/Y H:i',
          time_24hr: false,
          minDate: 'today'
        }
    }

    // `flatpickr` を適用
    flatpickr(this.element, options)
  }
}
