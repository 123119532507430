import { Controller } from '@hotwired/stimulus'

// 利用規約の同意チェックコントローラー
export default class extends Controller {
  static targets = ['checkbox', 'submit']

  connect() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    this.submitTarget.disabled = !this.checkboxTarget.checked
  }
}
