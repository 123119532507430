import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="toggle-required-date-controller"
export default class extends Controller {
  static targets = ['date', 'checkBox']

  connect() {}

  onDateChange() {
    // 本当は日にちを入力されたら1つはチェックボックスを選択されていることを必須にしたい
  }

  onCheckBoxChange() {
    const dateField = this.dateTarget

    // 第1希望の場合、フォーム遷移時に付与しているrequiredを維持
    if (dateField.id === 'first-date') {
      return // ここで処理を終了する
    }

    // 1つでもチェックボックスが選択されていれば required を付与
    if (this.checkBoxTargets.some(checkBox => checkBox.checked)) {
      dateField.setAttribute('required', 'required')
    } else {
      dateField.removeAttribute('required')
    }
  }
}
