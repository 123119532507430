import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['token', 'submitButton']

  connect() {
    const siteKey = this.data.get('siteKey')
    if (!siteKey) {
      console.error('reCAPTCHA Site Key is missing.')
      return
    }

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`
    script.async = true
    script.defer = true
    document.head.appendChild(script)
  }

  async execute(event) {
    event.preventDefault()

    /* global grecaptcha */
    grecaptcha.enterprise.ready(async () => {
      const siteKey = this.data.get('siteKey')
      const token = await grecaptcha.enterprise.execute(siteKey, {
        action: 'signup'
      })

      document.querySelector('input[name="recaptcha_token"]').value = token

      this.element.submit()
    })
  }
}
