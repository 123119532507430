import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="message"
export default class extends Controller {
  static targets = ['message']

  connect() {}

  toggleMessage(event) {
    event.preventDefault();
    this.messageTarget.classList.toggle('hidden');
  }
}
