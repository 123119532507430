import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'

// Connects to data-controller="phone-input"
export default class extends Controller {
  static targets = ['phoneInput', 'hiddenInput']

  connect() {
    // `intlTelInput` がすでに適用済みなら再適用しない
    if (this.phoneInputTarget.dataset.initialized === 'true') {
      return
    }
    this.phoneInputTarget.dataset.initialized = 'true'

    if (!this.hasPhoneInputTarget) {
      console.error('phoneInputTarget not found')
      return
    }

    this.addCustomStyles()

    this.iti = intlTelInput(this.phoneInputTarget, {
      containerClass: 'mt-1 w-full',
      initialCountry: 'jp',
      separateDialCode: true,
      autoPlaceholder: 'aggressive',
      loadUtils: () => import('intl-tel-input/utils')
    })

    this.phoneInputTarget.addEventListener('change', () =>
      this.updateHiddenInput()
    )
    this.phoneInputTarget.addEventListener('keyup', () =>
      this.updateHiddenInput()
    )
  }

  updateHiddenInput() {
    if (!this.hasHiddenInputTarget) {
      console.error('hiddenInputTarget not found')
      return
    }

    this.hiddenInputTarget.value = this.iti.getNumber()
  }
  addCustomStyles() {
    const style = document.createElement('style')
    style.innerHTML = `
      .iti__selected-dial-code {
        font-size: 0.9rem !important;
        margin-bottom: 0.1rem !important;
      }
      .iti__selected-country-primary {
        border-radius: 0.7rem 0 0 0.7rem !important;
      }
    `
    document.head.appendChild(style)
  }
}
